import { TextField as MuiTextField, TextFieldProps, Typography } from '@mui/material'
import clsx from 'clsx'
import { ChangeEvent, forwardRef, ReactNode } from 'react'

import { getInputPlaceholder, renderInputText } from '../helpers'
import { CommonInputProps, InputPlaceholder, InputText } from '../types'
import StyledInputContainer from './styles'

type Props = CommonInputProps &
  Partial<{
    value: string
    defaultValue: string
    maxLength: number
    placeholder: InputPlaceholder
    label: InputText
    caption: InputText
    prefix: ReactNode
    suffix: ReactNode
    isEditable: boolean
  }> &
  Pick<TextFieldProps, 'autoComplete' | 'onChange' | 'onBlur' | 'onFocus' | 'onKeyDown'>

const Input = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    placeholder,
    label,
    prefix,
    suffix,
    caption,
    isRequired,
    isError,
    onChange,
    isDisabled,
    className,
    isEditable = true,
    maxLength,
    ...other
  } = props

  const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(event)
  }

  const placeholderText = getInputPlaceholder()(placeholder)

  return (
    <StyledInputContainer className={className}>
      {label && (
        <Typography variant="body2" className="labelWrapper" color="neutrals.secondaryText">
          {renderInputText(label)}
          {isRequired && <div className="isRequired">*</div>}
        </Typography>
      )}
      <MuiTextField
        ref={ref}
        inputProps={{ maxLength }}
        type="text"
        className={clsx({ isError, isEditable })}
        onChange={onChangeInput}
        placeholder={placeholderText}
        InputProps={{
          readOnly: !isEditable,
          startAdornment: prefix && <div className="startAdornment">{prefix}</div>,
          endAdornment: suffix && <div className="endAdorned">{suffix}</div>,
          disableUnderline: true,
          disabled: isDisabled || !isEditable,
        }}
        {...other}
      />
      {caption && (
        <Typography variant="body2" className={clsx('captionWrapper', 'captionSpace', { isError })}>
          {renderInputText(caption)}
        </Typography>
      )}
    </StyledInputContainer>
  )
})

export default Input
