import { TransactionResponse } from '@ethersproject/providers'
import { TokenSymbol } from 'components/blocks/AmountInput/useAppCoins'
import {
  usePoolContract,
  useUniswapRouter,
  useUniswapV2Factory,
  useUSDTAddress,
  useWXFIAddress,
  useWXfiContract,
  useXUSDAddress,
} from 'constants/app-contracts'
import { ZERO_ADDRESS } from 'constants/misc'
import { TxTemplateTypes } from 'constants/transactions'
import { BigNumber } from 'ethers'
import { useTxTemplate } from 'hooks/base/tx-template'
import { useApiCall } from 'hooks/useApiCall'
import { useActiveWeb3React } from 'hooks/web3'
import { useCallback, useMemo, useState } from 'react'
import { useSingleCallResult } from 'state/multicall/hooks'
import { ZERO } from 'utils/isZero'

const API_URL = 'https://api-xapp.dapp-devs.com'

interface IPathResponse {
  Path: string[]
  Error?: string
}

const ONE_WEY = '1'

const useGetSwapPath = (
  token0?: string,
  token1?: string,
  amountIn = ONE_WEY
): {
  result?: IPathResponse
  pending: boolean
} => {
  const wxfiAddress = useWXFIAddress()

  const url = useMemo(() => {
    const targetIn = token0 === TokenSymbol.xfi ? wxfiAddress : token0
    const targetOut = token1 === TokenSymbol.xfi ? wxfiAddress : token1

    return `${API_URL}/get_path?token0=${targetIn}&token1=${targetOut}&amount_in=${amountIn?.toString()}`
  }, [token0, token1, amountIn, wxfiAddress])

  const { data, isLoading } = useApiCall(url)

  return {
    result: data,
    pending: isLoading,
  }
}

interface SwapTokens {
  address: string
  data: {
    name: string
    symbol: string
    decimals: number
  }
}

const XFI_API_MODEL = {
  address: TokenSymbol.xfi,
  data: {
    name: 'XFI',
    symbol: 'XFI',
    decimals: 18,
  },
}

export const useSwapTokensList = (): {
  result?: SwapTokens[]
  pending: boolean
} => {
  const { data, isLoading } = useApiCall(`${API_URL}/get_tokens_data`)

  return useMemo(() => {
    return {
      result: data ? [...data, XFI_API_MODEL] : [],
      pending: isLoading,
    }
  }, [data, isLoading])
}

export const useSwap = (
  tokenIn?: string,
  tokenOut?: string,
  amountIn: BigNumber = ZERO,
  amountOut: BigNumber = ZERO,
  setPendingTx?: (txHash: string) => void
) => {
  const contract = useUniswapRouter()
  const wXfiContract = useWXfiContract()
  const wXfiAddress = useWXFIAddress()

  const { account = '' } = useActiveWeb3React()

  const { result: path, pending } = useGetSwapPath(tokenIn, tokenOut)

  const isXfiToWxfi = tokenIn === TokenSymbol.xfi && tokenOut === wXfiAddress
  const iswXfiToXfi = tokenIn === wXfiAddress && tokenOut === TokenSymbol.xfi
  const isWrappedSwap = isXfiToWxfi || iswXfiToXfi

  const [swappedIn, setSwappedIn] = useState<BigNumber>()
  const [swappedOut, setSwappedOut] = useState<BigNumber>()

  const dataFunc = useCallback(
    async (clickedByUser: any) => {
      const deadline = getDeadline()

      if (!isWrappedSwap && !path?.Path) return

      if (clickedByUser) {
        setSwappedIn(amountIn)
        setSwappedOut(amountOut)
      }

      if (isXfiToWxfi) {
        return {
          ...(await wXfiContract?.populateTransaction.deposit()),
          value: amountIn,
        }
      }

      if (iswXfiToXfi) {
        return await wXfiContract?.populateTransaction.withdraw(amountIn)
      }

      if (tokenIn === TokenSymbol.xfi && path?.Path) {
        return {
          ...(await contract?.populateTransaction.swapExactETHForTokens(
            getAmountSlippage97(amountOut),
            path?.Path,
            account,
            deadline
          )),
          value: amountIn,
        }
      }

      if (tokenOut === TokenSymbol.xfi && path?.Path) {
        return await contract?.populateTransaction.swapExactTokensForETH(
          amountIn,
          getAmountSlippage97(amountOut),
          path?.Path,
          account,
          deadline
        )
      }

      if (!path?.Path) return

      return await contract?.populateTransaction.swapExactTokensForTokens(
        amountIn,
        getAmountSlippage97(amountOut),
        path?.Path,
        account,
        deadline
      )
    },
    [
      contract,
      amountIn,
      amountOut,
      path?.Path,
      account,
      wXfiContract,
      isWrappedSwap,
      isXfiToWxfi,
      iswXfiToXfi,
      tokenIn,
      tokenOut,
    ]
  )

  const setTx = useCallback(
    (tx: TransactionResponse) => {
      setPendingTx && setPendingTx(tx.hash)
    },
    [setPendingTx]
  )

  return {
    ...useTxTemplate(TxTemplateTypes.Swapped, `$swap_${tokenIn}_${tokenOut}`, `Swap tokens`, dataFunc, setTx),
    path,
    loadingPath: pending,
    isXfiToWxfi,
    iswXfiToXfi,
    isWrappedSwap,
    swappedIn,
    swappedOut,
  }
}

export const useSwapAmountOut = (amountIn: BigNumber = ZERO, tokenIn?: string, tokenOut?: string) => {
  const contract = useUniswapRouter()

  const { result: path } = useGetSwapPath(tokenIn, tokenOut)

  const deps = useMemo(() => {
    return [amountIn, path?.Path]
  }, [path, amountIn])

  const result = useSingleCallResult(contract, 'getAmountsOut', deps)

  return {
    amount: result.result?.amounts?.[result.result?.amounts?.length - 1] ?? null, // get last element
    loading: result.loading,
  }
}

export const useSwapAmountIn = (amountIn: BigNumber = ZERO, tokenIn?: string, tokenOut?: string) => {
  const contract = useUniswapRouter()

  const { result: path } = useGetSwapPath(tokenIn, tokenOut)

  const deps = useMemo(() => {
    return [amountIn, path?.Path]
  }, [path, amountIn])

  const result = useSingleCallResult(contract, 'getAmountsIn', deps)

  return {
    amount: result.result?.amounts?.[0] ?? null, // get first element
    loading: result.loading,
  }
}

const getDeadline = () => Math.floor(Date.now() / 1000) + 60 * 20 // 20 minutes from the current Unix time

const getAmountSlippage97 = (amount: BigNumber) => {
  return amount.mul(97).div(100)
}

export const useAddLiquiditySwap = (
  token0?: string,
  token1?: string,
  amount0: BigNumber = ZERO,
  amount1: BigNumber = ZERO,
  setPendingTx?: (txHash: string) => void
) => {
  const contract = useUniswapRouter()

  const { account = '' } = useActiveWeb3React()

  const { result: path, pending } = useGetSwapPath(token0, token1)

  const dataFunc = useCallback(async () => {
    const deadline = getDeadline() // 20 minutes from the current Unix time

    if (!token0 || !token1 || pending) return

    const isXfiIn = token0 === TokenSymbol.xfi
    const isXfiOut = token1 === TokenSymbol.xfi

    if (isXfiIn || isXfiOut) {
      const token = isXfiIn ? token1 : token0
      const amount = isXfiIn ? amount1 : amount0

      return {
        ...(await contract?.populateTransaction.addLiquidityETH(
          token,
          amount,
          getAmountSlippage97(amount),
          getAmountSlippage97(isXfiIn ? amount0 : amount1),
          account,
          deadline
        )),
        value: isXfiIn ? amount0 : amount1,
      }
    }

    return await contract?.populateTransaction.addLiquidity(
      token0,
      token1,
      amount0,
      amount1,
      getAmountSlippage97(amount0),
      getAmountSlippage97(amount1),
      account,
      deadline
    )
  }, [contract, amount0, amount1, account, token0, token1, pending])

  const setTx = useCallback(
    (tx: TransactionResponse) => {
      setPendingTx && setPendingTx(tx.hash)
    },
    [setPendingTx]
  )

  return {
    ...useTxTemplate(TxTemplateTypes.Swapped, `$add_liquidity_${token0}_${token1}`, `Add liquidity`, dataFunc, setTx),
    path,
    loadingPath: pending,
  }
}

export const useRemoveLiquidity = (
  tokenIn?: string,
  tokenOut?: string,
  amount: BigNumber = ZERO,
  setPendingTx?: (txHash: string) => void
) => {
  const contract = useUniswapRouter()

  const { account = '' } = useActiveWeb3React()

  const { result: path, pending } = useGetSwapPath(tokenIn, tokenOut)

  const dataFunc = useCallback(async () => {
    const deadline = getDeadline() // 20 minutes from the current Unix time

    if (!tokenIn || !tokenOut) return

    const isXfiIn = tokenIn === TokenSymbol.xfi
    const isXfiOut = tokenOut === TokenSymbol.xfi

    if (isXfiIn || isXfiOut) {
      return await contract?.populateTransaction.removeLiquidityETH(
        isXfiIn ? tokenOut : tokenIn,
        amount,
        0,
        0,
        account,
        deadline
      )
    }

    return await contract?.populateTransaction.removeLiquidity(tokenIn, tokenOut, amount, 0, 0, account, deadline)
  }, [contract, amount, account, tokenIn, tokenOut])

  const setTx = useCallback(
    (tx: TransactionResponse) => {
      setPendingTx && setPendingTx(tx.hash)
    },
    [setPendingTx]
  )

  return {
    ...useTxTemplate(
      TxTemplateTypes.Swapped,
      `$remove_liquidity_${tokenIn}_${tokenOut}`,
      `Removed liquidity`,
      dataFunc,
      setTx
    ),
    path,
    loadingPath: pending,
  }
}

const XFI_MODEL = {
  address: TokenSymbol.xfi,
  name: 'XFI',
  symbol: 'XFI',
  decimals: 18,
}

export const useSwapTokens = () => {
  const DEFAULT_TOKEN_OUT = useWXFIAddress()

  const [pendingTx, setPendingTx] = useState<string | undefined>('')

  const [tokenIn, setTokenIn] = useState<string>(XFI_MODEL.address)
  const [tokenOut, setTokenOut] = useState<string>(DEFAULT_TOKEN_OUT)

  const [amountOut, setAmountOut] = useState<BigNumber>()
  const [amountIn, setAmountIn] = useState<BigNumber>()

  const noValue = !amountIn || amountIn.isZero()

  const { result, pending: loadingAssets } = useSwapTokensList()

  const allowedAssets = useMemo(() => {
    return result?.filter(item => LP_ALLOWED_ASSETS.indexOf(item.data.symbol.toLowerCase()) !== -1) || []
  }, [result])

  const tokensInList = useMemo(() => {
    const targetAddress = tokenOut?.toLowerCase()

    return (
      allowedAssets
        ?.filter(item => item.address.toLowerCase() !== targetAddress)
        .map(token => ({
          ...token.data,
          address: token.address,
        })) || []
    )
  }, [allowedAssets, tokenOut])

  const tokensOutList = useMemo(() => {
    const targetAddress = tokenIn?.toLowerCase()

    return (
      allowedAssets
        ?.filter(item => item.address.toLowerCase() !== targetAddress)
        .map(token => ({
          ...token.data,
          address: token.address,
        })) || []
    )
  }, [allowedAssets, tokenIn])

  const tokenOutModel = useMemo(
    () => tokensOutList.find(item => item.address === tokenOut) || XFI_MODEL,
    [tokenOut, tokensOutList]
  )

  const tokenInModel = useMemo(
    () => tokensInList.find(item => item.address === tokenIn) || XFI_MODEL,
    [tokenIn, tokensInList]
  )

  return {
    pendingTx,
    setPendingTx,
    tokenIn,
    setTokenIn,
    tokenOut,
    setTokenOut,
    amountOut,
    setAmountOut,
    amountIn,
    setAmountIn,
    noValue,
    loadingAssets,
    tokensInList,
    tokensOutList,
    tokenOutModel,
    tokenInModel,
  }
}

const LP_ALLOWED_ASSETS = ['xusd', 'usdt', 'wxfi', 'xfi', 'empx', 'exe']

export const useLiquidityTokens = (inputToken0?: string, inputToken1?: string) => {
  const DEFAULT_TOKEN_IN = useUSDTAddress()
  const DEFAULT_TOKEN_OUT = useXUSDAddress()

  const [pendingTx, setPendingTx] = useState<string | undefined>('')

  const [tokenIn, setTokenIn] = useState<string>(inputToken0 || DEFAULT_TOKEN_IN)
  const [tokenOut, setTokenOut] = useState<string>(inputToken1 || DEFAULT_TOKEN_OUT)

  const [amountOut, setAmountOut] = useState<BigNumber>()
  const [amountIn, setAmountIn] = useState<BigNumber>()

  const pair = usePair(tokenIn, tokenOut)

  const { loading: loadingReserves, mapped } = useReserves(pair.pair)

  const noValue = !amountIn || amountIn.isZero()

  const { result, pending: loadingAssets } = useSwapTokensList()

  const allowedAssets = useMemo(() => {
    return result?.filter(item => LP_ALLOWED_ASSETS.indexOf(item.data.symbol.toLowerCase()) !== -1) || []
  }, [result])

  const tokensInList = useMemo(() => {
    const targetAddress = tokenOut?.toLowerCase()

    return (
      allowedAssets
        ?.filter(item => item.address.toLowerCase() !== targetAddress)
        .map(token => ({
          ...token.data,
          address: token.address,
        })) || []
    )
  }, [allowedAssets, tokenOut])

  const tokensOutList = useMemo(() => {
    const targetAddress = tokenIn?.toLowerCase()

    return (
      allowedAssets
        ?.filter(item => item.address.toLowerCase() !== targetAddress)
        .map(token => ({
          ...token.data,
          address: token.address,
        })) || []
    )
  }, [allowedAssets, tokenIn])

  const tokenOutModel = useMemo(
    () => tokensOutList.find(item => item.address === tokenOut) || XFI_MODEL,
    [tokenOut, tokensOutList]
  )

  const tokenInModel = useMemo(
    () => tokensInList.find(item => item.address === tokenIn) || XFI_MODEL,
    [tokenIn, tokensInList]
  )

  return {
    pendingTx,
    setPendingTx,
    tokenIn,
    setTokenIn,
    tokenOut,
    setTokenOut,
    amountOut,
    setAmountOut,
    amountIn,
    setAmountIn,
    noValue,
    loadingAssets,
    tokensInList,
    tokensOutList,
    tokenOutModel,
    tokenInModel,
    reserves: mapped,
    loadingReserves,
    pair: pair.pair,
  }
}

export const useRemoveLiquidityTokens = (inputToken0: string, inputToken1: string, lp: string) => {
  const DEFAULT_TOKEN_IN = useUSDTAddress()
  const DEFAULT_TOKEN_OUT = useXUSDAddress()

  const [pendingTx, setPendingTx] = useState<string | undefined>('')

  const tokenIn = inputToken0 || DEFAULT_TOKEN_IN
  const tokenOut = inputToken1 || DEFAULT_TOKEN_OUT

  const [amountIn, setAmountIn] = useState<BigNumber>()

  const pair = usePair(tokenIn, tokenOut)

  const noValue = !amountIn || amountIn.isZero()

  const tokenInModel = useMemo(
    () => ({
      symbol: lp,
      address: pair.pair,
    }),
    [pair.pair, lp]
  )

  return {
    tokenIn,
    tokenOut,
    amountIn,
    setPendingTx,
    noValue,
    pendingTx,
    tokenInModel,
    setAmountIn,

    pair: pair.pair,
  }
}

const usePair = (
  tokenIn?: string,
  tokenOut?: string
): {
  pair: string
  loading: boolean
} => {
  const contract = useUniswapV2Factory()

  const wxfiAddress = useWXFIAddress()

  const deps = useMemo(() => {
    return [tokenIn === TokenSymbol.xfi ? wxfiAddress : tokenIn, tokenOut === TokenSymbol.xfi ? wxfiAddress : tokenOut]
  }, [tokenIn, tokenOut, wxfiAddress])

  const result = useSingleCallResult(contract, 'getPair', deps)

  return {
    pair: result?.result?.[0] || ZERO_ADDRESS, // get last element
    loading: result.loading,
  }
}

const useReserves = (pair: string) => {
  const contract = usePoolContract(pair)

  const reservesR = useSingleCallResult(contract, 'getReserves')
  const token0R = useSingleCallResult(contract, 'token0')
  const token1R = useSingleCallResult(contract, 'token1')

  return useMemo(() => {
    const token0 = token0R.result?.toString().toLowerCase()
    const token1 = token1R.result?.toString().toLowerCase()

    if (reservesR.loading || token0R.loading || token1R.loading) return { loading: true }

    const mapped: {
      [key: string]: BigNumber | undefined
    } =
      token0 && token1
        ? {
            [token0]: reservesR.result?._reserve0,
            [token1]: reservesR.result?._reserve1,
          }
        : {}

    return {
      loading: false,
      mapped,
    }
  }, [reservesR, token0R, token1R])
}
