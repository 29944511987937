import { createTheme } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxs: true
    xxl: true
  }
}

const muiTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  typography: {
    fontFamily: ['Neue Montreal'].join(','),
  },
  breakpoints: {
    values: {
      xxs: 0,
      xs: 481,
      sm: 769,
      md: 1025,
      lg: 1536,
      xl: 1920,
      xxl: 2560,
    },
  },
  spacing: 1,
  components: {
    MuiTypography: {
      defaultProps: {
        fontSize: 17,
        lineHeight: 'normal',
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          padding: '0',
          lineHeight: 'normal',
          fontSize: 17,
        },
      },
    },
  },
})

export default muiTheme
