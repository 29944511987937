import { Stack } from '@mui/material'
import { ButtonPrimary } from 'components/Button'
import AuthLayout from 'components/layouts/AuthLayout'
import Input from 'components/MUI/inputs/Input'
import { DEV_APP_PASSWORD } from 'constants/devModePassword'
import { LOCAL_STORAGE_FIELDS } from 'constants/localStorage'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { ChangeEvent, PropsWithChildren, useLayoutEffect, useState } from 'react'

const isDevelop = process.env.REACT_APP_PUBLIC_APP_ENV === 'development'

const DevModWrapper = ({ children }: PropsWithChildren) => {
  const [isShowContent, setIsShowContent] = useState(isDevelop)
  const [inputValue, setInputValue] = useState('')
  const [password, setPassword] = useLocalStorage<string>(LOCAL_STORAGE_FIELDS.APP_PASSWORD, '')

  useLayoutEffect(() => {
    if (isDevelop) {
      const storedAppPassword = atob(password)

      setIsShowContent(storedAppPassword !== DEV_APP_PASSWORD)
    }
  }, [])

  const handleChangeInput = (value: ChangeEvent<HTMLInputElement>) => {
    setInputValue(value.target.value)
  }

  const handleSubmitDevPassword = () => {
    if (inputValue === DEV_APP_PASSWORD) {
      setPassword(btoa(inputValue))

      setIsShowContent(false)
    }
  }

  return isShowContent ? (
    <AuthLayout>
      <Stack maxWidth="33.75rem" height="100%" justifyContent="center" margin="0 auto" gap="2rem">
        <Input placeholder={{ type: 'text', text: 'Code' }} onChange={handleChangeInput} />
        <ButtonPrimary onClick={handleSubmitDevPassword}>Submit</ButtonPrimary>
      </Stack>
    </AuthLayout>
  ) : (
    <>{children}</>
  )
}

export default DevModWrapper
