import { Box, Stack, styled } from '@mui/material'
import { PropsWithChildren } from 'react'

import leftSvg from '../../../assets/icons/left-logo.svg'
import rightSvg from '../../../assets/icons/right-logo.svg'

const StyledAuthLayout = styled(Box, { name: 'StyledAuthLayout' })(({ theme }) => ({
  height: '100vh',
  padding: '4rem 10rem',

  [theme.breakpoints.down('md')]: {
    padding: '3rem 1rem',
  },
}))

const AuthLayout = ({ children }: PropsWithChildren) => {
  return (
    <StyledAuthLayout>
      <Stack flexDirection="row" gap="16px" alignItems="center" justifyContent="center">
        <img src={leftSvg} alt="left" />
        <img src={rightSvg} alt="right" />
      </Stack>
      {children}
    </StyledAuthLayout>
  )
}

export default AuthLayout
