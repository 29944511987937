import walletSvg from 'assets/icons/wallet.svg'
import { ConfirmInWalletBlock } from 'components/Approval/ApproveTx'
import { AmountInputWithMax } from 'components/blocks/AmountInput/AmountInput'
import { TokenSymbol } from 'components/blocks/AmountInput/useAppCoins'
import { ButtonDark } from 'components/Button'
import { AutoColumn } from 'components/Column'
import { FormActionBtn } from 'components/FormActionBtn/FormActionBtn'
import { TransactionInfo } from 'components/TransactionInfo/TransactionInfo'
import { TxStatusView } from 'components/TxStatusView/TxStatusView'
import { BigNumber } from 'ethers'
import { Divider } from 'pages/Staking/styled'
import { useCallback, useMemo, useState } from 'react'
import { useNativeCurrencyBalance } from 'state/wallet/hooks'
import { ZERO } from 'utils/isZero'

import { useLoadParams, useTakeLoan } from './hooks'
import { getAllowedXUsd, getMaxAllowedXfi } from './utils'
import { XUsdBlock } from './xUsdBlock/xUsdBlock'

export const TakingLoan = () => {
  const [amountXfi, setAmountXfi] = useState<BigNumber | undefined>(ZERO)
  const [amountXUsd, setAmountXUsd] = useState<BigNumber | undefined>(ZERO)

  const [pendingTx, setPendingTx] = useState<string | undefined>('')

  const { getPriceFeeds, collateralRatio } = useLoadParams()

  const xfiBalance = useNativeCurrencyBalance()

  const { pending, action, isError, txInfo, calledWallet } = useTakeLoan(amountXUsd, amountXfi, setPendingTx)

  const noValue = (!amountXUsd && !amountXfi) || (amountXfi?.isZero() && amountXUsd?.isZero())

  const maxAvailabledXUsd = useMemo(() => {
    return getAllowedXUsd(xfiBalance, getPriceFeeds, collateralRatio)
  }, [getPriceFeeds, collateralRatio, xfiBalance])

  const handleXfiValue = useCallback(
    (value: BigNumber) => {
      const allowed = getAllowedXUsd(value, getPriceFeeds, collateralRatio)

      setAmountXfi(value)
      setAmountXUsd(allowed)
    },
    [getPriceFeeds, collateralRatio]
  )

  const handleXUsdValue = useCallback(
    (value: BigNumber) => {
      const allowed = getMaxAllowedXfi(value, getPriceFeeds, collateralRatio)

      setAmountXUsd(value)
      setAmountXfi(allowed)
    },
    [getPriceFeeds, collateralRatio]
  )

  return (
    <>
      <AutoColumn gap="16px">
        {pendingTx ? (
          <TxStatusView
            amountIn={amountXUsd}
            amountOut={amountXUsd}
            isLoading={pending}
            processLabel="You are about to receive"
            completedLabel="Loan was taken!"
            color="dark"
            hash={pendingTx}
            token={TokenSymbol.xUsd}
          >
            <ButtonDark onClick={() => setPendingTx('')}>Manage Position</ButtonDark>
          </TxStatusView>
        ) : (
          <>
            <AmountInputWithMax
              label="Collateral assets"
              inputValue={amountXfi}
              setInputValue={v => v && handleXfiValue(v)}
              decimals={18}
              rightToken={{
                symbol: TokenSymbol.xfi,
              }}
              walletIcon={walletSvg}
              balance={xfiBalance}
              useBalanceAsMax
            />

            <AmountInputWithMax
              label="XUSD to borrow"
              inputValue={amountXUsd}
              setInputValue={v => v && handleXUsdValue(v)}
              decimals={18}
              rightToken={{
                symbol: TokenSymbol.xUsd,
              }}
              walletIcon={walletSvg}
              balance={maxAvailabledXUsd}
              validateBalanceExceedsZero={false}
            />

            <ConfirmInWalletBlock calledWallet={calledWallet}>
              {noValue ? (
                <ButtonDark disabled={noValue}>Enter an amount</ButtonDark>
              ) : (
                <ButtonDark onClick={action} disabled={isError}>
                  <FormActionBtn pending={pending} txInfo={txInfo} labelActive="Take loan" labelInProgress="Taking" />
                </ButtonDark>
              )}
            </ConfirmInWalletBlock>

            <Divider />

            <XUsdBlock />
          </>
        )}

        <TransactionInfo info={txInfo} />
      </AutoColumn>
    </>
  )
}
