import { useActiveWeb3React } from 'hooks/web3'
import { useMemo } from 'react'

import deBridgeAbi from '../abis/de-bridge/de-bridge-gate.json'
import erc20Abi from '../abis/erc20.json'
import {
  DeBridgeGate,
  Erc20,
  LayerZeroResolver,
  Staking,
  StakingXusdUsdt,
  UniswapPool,
  UniswapV2Factory,
  UniswapV2Router,
  WrappedOmnichainXfi,
  Wxfi,
  XusdCredit,
} from '../abis/types'
import stakingAbi from '../abis/xapp/Staking.json'
import stakingXusdUsdtAbi from '../abis/xapp/staking-xusd-usdt.json'
import layerZeroErc20Abi from '../abis/xapp/wrapped-omnichain-xfi.json'
import { useContract } from '../hooks/useContract'
import layerZeroResolverAbi from './../abis/layer-zero-resolver.json'
import uniswapPool from './../abis/uniswap/uniswap-pool.json'
import uniswapFactoryAbi from './../abis/uniswap/uniswap-v2-factory.json'
import uniswapRouterAbi from './../abis/uniswap/uniswap-v2-router.json'
import wxfiAbi from './../abis/xapp/wxfi.json'
import xUsdCreditAbi from './../abis/xapp/xusd-credit.json'
import { SupportedChainId } from './chainsinfo'

const XUSD_WETH_LP_STAKING_ADDRESS = '0x8D1dd64aC4306274585ad0BE302283A8D40a8383'
const XUSD_USDT_LP_STAKING_ADDRESS = '0xc74b7350776ef5d4d10cecfad7C84b3E5A13a9F4'
const eMPX_XFI_STAKING_ADDRESS = '0x97ae3057df82D9c7eFa6A9526B537F6B62F58dD3'

const DE_BRIDGE_GATE = '0x43dE2d77BF8027e25dBD179B491e8d64f38398aA'

export const LP_XUSD_WETH_ADDRESS = '0xf4dc9dfdb41431a9c9b834239ece79fc311a0184' // XUSD-WETH
const LP_XUSD_USDT_ADDRESS = '0xae8e45b0f48b274f4d59de325017a7c60b5d11be' // XUSD-USDT
const LP_eMPX_XFI_TOKEN = '0x6486cf49f3fbee7e3db48fd77ba04307e68ad583' // eMPX-wXFI

const eMPX_TESTNET = '0x54764B050f1129b22e365744Fe3f440Bc3aaD292'

export const WETH_TESTNET = '0x74f4B6c7F7F518202231b58CE6e8736DF6B50A81'

const WRAPPED_oXFI_BSC = '0x7e1B68D16C4ACfDcdE38f6eAAbebB34eb3D5E2CB'
const oXFI_BSC = '0x922b644d46AbBc5Faec10d832E2b5826A15baA1c'

const BRIDGABLE_WXFI_ADDRESSES = {
  arbitrum: '0x9911396637B044d2182B6D01030Cf6378664d163',
  optimism: '0xDac71a61e1D9757D43f63e7dB1c870F83cC1ADb6',
  binance: '0x7e1B68D16C4ACfDcdE38f6eAAbebB34eb3D5E2CB',
  polygon: '0x9911396637B044d2182B6D01030Cf6378664d163',
}

const xUSD_CREDIT = '0x31063f9Af2925ca43722F3A1728445D079bf4cDe'

const USDT_TESTNET = '0x83E9A41c38D71f7a06632dE275877FcA48827870'
const XUSD_TESTNET = '0xC692E6EDe21eC911D9E35C6A52bdD31b2d4b4425'
//  https://scan.testnet.ms/address/0x8c4a6c98966441145315106b0f5f629Ddf44A161/read-contract#address-tabs
const UNI_ROUTER_TESTNET = '0x63214cB45714b55e17bc58dd879BdD62EE1B024b'

// 0xB62cfA9e79f3b085004BE608a316845A5be3f8b0 - WXFI/SHIB
// 0xbF1997De68E59F66aceDd049eaeb555e6B54bF8B - GOJO/SHIB

const WXFI_TESTNET = '0x28cC5eDd54B1E4565317C3e0Cfab551926A4CD2a'
// WXFI https://scan.testnet.ms/address/0x28cC5eDd54B1E4565317C3e0Cfab551926A4CD2a
// SHIBA 0x7A09C7c16959B68fdA3cDDB47abe0B3a9ce8100b
// GOJO 0x18Ca0d6d0b0571372e642191888967BF74702F3B
// BROWNIE 0x7cfB91ccb4e487692015eD8e62A41ea74abe8a48

//  https://scan.testnet.ms/address/0x49B6c694eE6B7E3017952072D44065D169Bbf894/read-contract#address-tabs
const UniswapV2Factory_TESTNET = '0x34d42F1C6a207BB951781694a007A032853B72FD'

export const useStakingXusdWethLpAddress = () => {
  const { chainId } = useActiveWeb3React()

  return useMemo(() => {
    return getSourceByNetwork(
      chainId,
      '',
      LP_XUSD_WETH_ADDRESS,
      LP_XUSD_WETH_ADDRESS,
      LP_XUSD_WETH_ADDRESS,
      LP_XUSD_WETH_ADDRESS,
      LP_XUSD_WETH_ADDRESS,
      LP_XUSD_WETH_ADDRESS
    )
  }, [chainId])
}

export const useStakingXusdUsdtLpAddress = () => {
  const { chainId } = useActiveWeb3React()

  return useMemo(() => {
    return getSourceByNetwork(
      chainId,
      '',
      LP_XUSD_USDT_ADDRESS,
      LP_XUSD_USDT_ADDRESS,
      LP_XUSD_USDT_ADDRESS,
      LP_XUSD_USDT_ADDRESS,
      LP_XUSD_USDT_ADDRESS,
      LP_XUSD_USDT_ADDRESS
    )
  }, [chainId])
}

const useStakingMpxAddress = () => {
  const { chainId } = useActiveWeb3React()

  return useMemo(() => {
    return getSourceByNetwork(chainId, '', eMPX_XFI_STAKING_ADDRESS, eMPX_XFI_STAKING_ADDRESS)
  }, [chainId])
}

const useDeBridgeGateAddress = () => {
  const { chainId } = useActiveWeb3React()

  return useMemo(() => {
    return getSourceByNetwork(
      chainId,
      '',
      '',
      DE_BRIDGE_GATE,
      DE_BRIDGE_GATE,
      DE_BRIDGE_GATE,
      DE_BRIDGE_GATE,
      DE_BRIDGE_GATE
    )
  }, [chainId])
}

export const useWEthAddress = () => {
  const { chainId } = useActiveWeb3React()

  return useMemo(() => {
    return getSourceByNetwork(chainId, '', WETH_TESTNET)
  }, [chainId])
}

export const useEmpxAddress = () => {
  const { chainId } = useActiveWeb3React()

  return useMemo(() => {
    return getSourceByNetwork(chainId, '', eMPX_TESTNET)
  }, [chainId])
}

export const useDeBridgeGateContract = () => {
  const address = useDeBridgeGateAddress()

  return useContract<DeBridgeGate>(address, deBridgeAbi)
}

export const useBridgableWXfiAddress = () => {
  const { chainId } = useActiveWeb3React()

  return useMemo(() => {
    return getSourceByNetwork(
      chainId,
      '',
      '',
      BRIDGABLE_WXFI_ADDRESSES.binance,
      BRIDGABLE_WXFI_ADDRESSES.arbitrum,
      '',
      BRIDGABLE_WXFI_ADDRESSES.polygon,
      BRIDGABLE_WXFI_ADDRESSES.optimism
    )
  }, [chainId])
}

export const usePoolContract = (address: string) => {
  return useContract<UniswapPool>(address, uniswapPool)
}

export const useErc20Contract = (token?: string) => {
  return useContract<Erc20>(token, erc20Abi)
}

export const useStakingXUSDWethLPContract = () => {
  const address = useStakingXusdWethLpAddress()

  return useErc20Contract(address)
}

export const useStakingXUSDUsdtPContract = () => {
  const address = useStakingXusdUsdtLpAddress()

  return useErc20Contract(address)
}

export const useXusdWethLpStakingContract = () => {
  const address = useXusdWethAddress()

  return useContract<Staking>(address, stakingAbi)
}

export const useMpxStakingContract = () => {
  const address = useStakingMpxAddress()

  return useContract<Staking>(address, stakingAbi)
}

export const useXusdUsdLpStakingContract = () => {
  const address = useXusdUsdtLpAddress()

  return useContract<StakingXusdUsdt>(address, stakingXusdUsdtAbi)
}

export const useXusdUsdtLpAddress = () => {
  const { chainId } = useActiveWeb3React()

  return useMemo(() => {
    return getSourceByNetwork(chainId, '', XUSD_USDT_LP_STAKING_ADDRESS)
  }, [chainId])
}

const useXusdWethAddress = () => {
  const { chainId } = useActiveWeb3React()

  return useMemo(() => {
    return getSourceByNetwork(chainId, '', XUSD_WETH_LP_STAKING_ADDRESS)
  }, [chainId])
}

export const useEMpxXfiLPAddress = () => {
  const { chainId } = useActiveWeb3React()

  return useMemo(() => {
    return getSourceByNetwork(
      chainId,
      '',
      LP_eMPX_XFI_TOKEN,
      LP_eMPX_XFI_TOKEN,
      LP_eMPX_XFI_TOKEN,
      LP_eMPX_XFI_TOKEN,
      LP_eMPX_XFI_TOKEN,
      LP_eMPX_XFI_TOKEN
    )
  }, [chainId])
}

export const useEMpxXfiContract = () => {
  const address = useEMpxXfiLPAddress()

  return useContract<Erc20>(address, stakingXusdUsdtAbi)
}

const getSourceByNetwork = (
  chainId: number | undefined,
  xfiMainnet = '',
  xfiTestnet = '',
  bsc = '',
  arbitrum = '',
  avalanche = '',
  polygon = '',
  optimism = ''
): string => {
  if (chainId === SupportedChainId.XFI_TESTNET) {
    return xfiTestnet || ''
  } else if (chainId === SupportedChainId.BNB) {
    return bsc
  } else if (chainId === SupportedChainId.ARBITRUM_ONE) {
    return arbitrum
  } else if (chainId === SupportedChainId.AVALANCHE) {
    return avalanche
  }

  if (chainId === SupportedChainId.POLYGON) {
    return polygon
  }

  if (chainId === SupportedChainId.OPTIMISM) {
    return optimism
  }

  return ''
}

const LAYER_ZERO_ENDPOINTS = {
  hardhat: '0x66A71Dcef29A0fFBDBE3c6a460a3B5BC225Cd675',
  ethereum: '0x66A71Dcef29A0fFBDBE3c6a460a3B5BC225Cd675',
  bsc: '0x3c2269811836af69497E5F486A85D7316753cf62',
  avalanche: '0x3c2269811836af69497E5F486A85D7316753cf62',
  polygon: '0x3c2269811836af69497E5F486A85D7316753cf62',
  arbitrum: '0x3c2269811836af69497E5F486A85D7316753cf62',
  optimism: '0x3c2269811836af69497E5F486A85D7316753cf62',
  fantom: '0xb6319cC6c8c27A8F5dAF0dD3DF91EA35C4720dd7',
  goerli: '0xbfD2135BFfbb0B5378b56643c2Df8a87552Bfa23',
  'bsc-testnet': '0x6Fcb97553D41516Cb228ac03FdC8B9a0a9df04A1',
  fuji: '0x93f54D755A063cE7bB9e6Ac47Eccc8e33411d706',
  mumbai: '0xf69186dfBa60DdB133E91E9A4B5673624293d8F8',
  'arbitrum-goerli': '0x6aB5Ae6822647046626e83ee6dB8187151E1d5ab',
  'optimism-goerli': '0xae92d5aD7583AD66E49A0c67BAd18F6ba52dDDc1',
  'fantom-testnet': '0x7dcAD72640F835B0FA36EFD3D6d3ec902C7E5acf',
  moonbase: '0x6Fcb97553D41516Cb228ac03FdC8B9a0a9df04A1',
  moonbeam: '0x9740FF91F1985D8d2B71494aE1A2f723bb3Ed9E4',
}

const useLayerZeroResolverAddress = () => {
  const { chainId } = useActiveWeb3React()

  return useMemo(() => {
    return getSourceByNetwork(chainId, '', '', LAYER_ZERO_ENDPOINTS.bsc, LAYER_ZERO_ENDPOINTS.arbitrum)
  }, [chainId])
}

export const useLayerZeroResolverContract = () => {
  const address = useLayerZeroResolverAddress()

  return useContract<LayerZeroResolver>(address, layerZeroResolverAbi)
}

export const useLayerZeroErc20Contract = () => {
  const address = useLayerZeroErc20Address()

  return useContract<WrappedOmnichainXfi>(address, layerZeroErc20Abi)
}

export const useCreditContract = () => {
  return useContract<XusdCredit>(xUSD_CREDIT, xUsdCreditAbi)
}

const useLayerZeroErc20Address = () => {
  const { chainId } = useActiveWeb3React()

  return useMemo(() => {
    return getSourceByNetwork(chainId, '', '', WRAPPED_oXFI_BSC, oXFI_BSC, oXFI_BSC, oXFI_BSC, oXFI_BSC)
  }, [chainId])
}

const useUniswapV2FactoryAddress = () => {
  const { chainId } = useActiveWeb3React()

  return useMemo(() => {
    return getSourceByNetwork(chainId, UniswapV2Factory_TESTNET, UniswapV2Factory_TESTNET)
  }, [chainId])
}

export const useWXFIAddress = () => {
  const { chainId } = useActiveWeb3React()

  return useMemo(() => {
    return getSourceByNetwork(chainId, '', WXFI_TESTNET)
  }, [chainId])
}

export const useWXfiContract = () => {
  const address = useWXFIAddress()

  return useContract<Wxfi>(address, wxfiAbi)
}

export const useUSDTAddress = () => {
  const { chainId } = useActiveWeb3React()

  return useMemo(() => {
    return getSourceByNetwork(chainId, USDT_TESTNET, USDT_TESTNET)
  }, [chainId])
}

export const useXUSDAddress = () => {
  const { chainId } = useActiveWeb3React()

  return useMemo(() => {
    return getSourceByNetwork(chainId, XUSD_TESTNET, XUSD_TESTNET)
  }, [chainId])
}

export const useUniswapV2Factory = () => {
  const address = useUniswapV2FactoryAddress()

  return useContract<UniswapV2Factory>(address, uniswapFactoryAbi)
}

const useUniswapRouterAddress = () => {
  const { chainId } = useActiveWeb3React()

  return useMemo(() => {
    return getSourceByNetwork(chainId, UNI_ROUTER_TESTNET, UNI_ROUTER_TESTNET)
  }, [chainId])
}

export const useUniswapRouter = () => {
  const address = useUniswapRouterAddress()

  return useContract<UniswapV2Router>(address, uniswapRouterAbi)
}
