import { useWarningFlag } from 'components/WarningWrapper/WarningWrapper'
import { useMenuArray } from 'constants/menu'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

const MobileMenuWrapper = styled.div`
  position: fixed;

  padding-bottom: 19px;
  padding-top: 5px;

  bottom: 0;

  z-index: 100;
  display: flex;
  justify-content: center;

  max-width: 100%;

  background: linear-gradient(180.44deg, rgba(244, 245, 251, 0) 6.37%, #f4f5fb 27.63%);

  ${({ theme }) => theme.mediaWidth.upToTablet`
    width: 100%;
  `};
`

const MobileMenuScroll = styled.div`
  display: flex;
  gap: 20px;
  overflow-x: auto;
  padding: 0 16px;

  &::-webkit-scrollbar {
    display: none;
  }
`

const MobileMenuButton = styled(NavLink)<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 6px;
  cursor: pointer;

  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};

  span {
    color: ${({ theme }) => theme.dark30};
  }

  &.active {
    span {
      color: ${({ theme }) => theme.dark};
    }
  }
`

const MobileIcon = styled.img`
  width: 52px;
  height: 52px;
  gap: 20px;
  background-color: ${({ theme }) => theme.light};
  border-radius: 50%;
  border: 6px solid ${({ theme }) => theme.light};
  box-shadow: 0px 3.333px 16.667px 0px rgba(40, 46, 63, 0.08);
`

const MobileMenuLabel = styled.span`
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.text1};
`

export const MobileMenu = () => {
  const menuArray = useMenuArray()

  const { lockedForCurrentChain } = useWarningFlag()

  return (
    <MobileMenuWrapper>
      <MobileMenuScroll>
        {menuArray.map(({ href, src, label, disabled }) => (
          <MobileMenuButton to={href} key={href} disabled={lockedForCurrentChain || disabled}>
            <MobileIcon src={src} />

            <MobileMenuLabel>{label}</MobileMenuLabel>
          </MobileMenuButton>
        ))}
      </MobileMenuScroll>
    </MobileMenuWrapper>
  )
}
