import styled from 'styled-components'

const StyledInputContainer = styled('div')(({ theme }) => {
  return {
    position: 'relative',
    padding: '0.75rem 1.25rem',
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    borderRadius: '1.5rem',
    background: theme.dark04,

    '& .startAdornment': {
      display: 'flex',
      alignItems: 'center',
    },

    '& .endAdorned': {
      display: 'flex',
      alignItems: 'center',
      width: 'fit-content',
      height: 'fit-content',
      flexShrink: 0,
      cursor: 'pointer',
      marginLeft: '1rem',
      zIndex: 2,
    },

    '.MuiInputBase-input': {
      padding: 0,
    },

    '& .labelWrapper': {
      display: 'flex',
      gap: 2,

      '& .isRequired': {
        color: theme.text1,
      },
    },

    '& .captionWrapper': {
      position: 'absolute',
      bottom: '-1.56rem',
      color: theme.text1,

      '&.isError': {
        color: theme.dark,
      },
    },

    '&::placeholder': {
      color: theme.dark30,
      WebkitTextFillColor: theme.dark30,
    },

    fieldset: {
      border: 'none',
    },

    '& .captionSpace': {
      height: '1.25rem',
    },

    '& .MuiInputBase-root:not(.isEditable)': {
      color: theme.text1,

      input: {
        height: 'auto',
        fontSize: '1.5rem',

        '&:-webkit-autofill': {
          WebkitBoxShadow: 'none',
          WebkitTextFillColor: theme.text1,
          caretColor: theme.text1,
        },
      },
    },

    '& .Mui-disabled': {
      WebkitTextFillColor: theme.dark30,
    },
  }
})

export default StyledInputContainer
