import { AppToggler } from 'components/AppToggler/AppToggler'
import { TokenSymbol } from 'components/blocks/AmountInput/useAppCoins'
import { CardCenteredGap } from 'components/Card'
import { useEmpxAddress, useUSDTAddress, useWXFIAddress, useXUSDAddress } from 'constants/app-contracts'
import { SupportedChainId } from 'constants/chainsinfo'
import { LOCAL_STORAGE_FIELDS } from 'constants/localStorage'
import { useLocalStorage } from 'hooks/useLocalStorage'
import LiqudityBlock from 'pages/Swap/AddLiqudityBlock'
import { useLayoutEffect, useRef } from 'react'

import { GetHeader } from './PendingView'
import { PageWrapper } from './styled'

const TAB_IDS = {
  lpXFI: TokenSymbol.lpXFI,
  lpMPX: 'lpMPX',
  lpUSD: 'lpUSD',
}

const TABS = [
  {
    id: TAB_IDS.lpXFI,
    title: 'lpXFI',
  },
  {
    id: TAB_IDS.lpMPX,
    title: 'lpMPX',
  },
  {
    id: TAB_IDS.lpUSD,
    title: 'lpUSD',
  },
]

export default function Get() {
  const [tab, setTab] = useLocalStorage<string>(LOCAL_STORAGE_FIELDS.GET_TAB, TABS[0].id)

  const usdt = useUSDTAddress()
  const xusd = useXUSDAddress()

  const wxfi = useWXFIAddress()
  const empx = useEmpxAddress()

  return (
    <PageWrapper maxWidth={tab === TABS[0].id ? '650px' : '600px'}>
      <CardCenteredGap gap="16px">
        <GetHeader />

        <AppToggler tab={tab} setTab={setTab} tabs={TABS} />

        {tab === TAB_IDS.lpXFI && <LiqudityBlock inputToken0={wxfi} inputToken1={xusd} lp={TAB_IDS.lpXFI} />}

        {tab === TAB_IDS.lpUSD && <LiqudityBlock inputToken0={usdt} inputToken1={xusd} lp={TAB_IDS.lpUSD} />}

        {tab === TAB_IDS.lpMPX && <LiqudityBlock inputToken0={empx} inputToken1={xusd} lp={TAB_IDS.lpMPX} />}
      </CardCenteredGap>
    </PageWrapper>
  )
}

const CrossChain = () => {
  const installed = useRef(false)

  useLayoutEffect(() => {
    !installed.current &&
      // @ts-ignore
      window.deBridge?.widget({
        v: '1',
        element: 'debridgeWidget',
        title: 'GET xApp',
        description: '',
        width: Math.min(window.innerWidth - 40, 600),
        height: Math.min(window.innerHeight - 40, 600),
        r: null,
        supportedChains:
          '{"inputChains":{"1":"all","10":"all","56":"all","137":"all","8453":"all","42161":"all","43114":"all","59144":"all","7565164":"all","245022934":"all"},"outputChains":{"1":"all","10":"all","56":"all","137":"all","8453":"all","42161":"all","43114":"all","59144":"all","7565164":"all","245022934":"all"}}',
        inputChain: 1,
        outputChain: SupportedChainId.OPTIMISM,
        inputCurrency: '',
        outputCurrency: '',
        address: '',
        showSwapTransfer: true,
        amount: '',
        outputAmount: '',
        isAmountFromNotModifiable: false,
        isAmountToNotModifiable: false,
        lang: 'en',
        mode: 'deswap',
        isEnableCalldata: false,
        styles:
          'eyJhcHBCYWNrZ3JvdW5kIjoiI2Y4ZmFmZiIsImJvcmRlclJhZGl1cyI6OCwiZm9ybUNvbnRyb2xCZyI6IiNmNGY2ZmIiLCJkcm9wZG93bkJnIjoiI2Y0ZjZmYiIsInByaW1hcnkiOiIjNjA5ZWZjIiwic2Vjb25kYXJ5IjoiIzYwOWVmYyIsImxpZ2h0IjoiI2Y4ZmFmZiIsInN1Y2Nlc3MiOiIjNjNiMjAwIiwiZXJyb3IiOiIjZmYwZDQ3Iiwid2FybmluZyI6IiNmY2FiNjAiLCJpY29uQ29sb3IiOiIjNjA5ZWZjIiwiZm9udENvbG9yQWNjZW50IjoiIzYwOWVmYyIsImZvbnRGYW1pbHkiOiJSb2JvdG8iLCJwcmltYXJ5QnRuVGV4dCI6IiNmOGZhZmYiLCJzZWNvbmRhcnlCdG5UZXh0IjoiI2Y4ZmFmZiJ9',
        theme: 'light',
        isHideLogo: true,
        logo: '',
      })

    installed.current = true
  }, [])

  return (
    <>
      <div id="debridgeWidget"></div>
    </>
  )
}
