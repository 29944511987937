import { evm } from '@debridge-finance/desdk'
import { Flags } from '@debridge-finance/desdk/lib/evm'
import { TransactionResponse } from '@ethersproject/providers'
import { useBridgableWXfiAddress, useDeBridgeGateContract } from 'constants/app-contracts'
import { SupportedChainId } from 'constants/chainsinfo'
import { RPC_URLS } from 'constants/networks'
import { BigNumber } from 'ethers'
import { useTxTemplate } from 'hooks/base/tx-template'
import { useERC20Symbol } from 'hooks/useContractName'
import { useActiveWeb3React } from 'hooks/web3'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAddPopup } from 'state/application/hooks'
import { useSingleCallResult } from 'state/multicall/hooks'
import { useAllChainsTransactions } from 'state/transactions/hooks'
import { ZERO } from 'utils/isZero'
import { formatDecimal } from 'utils/numberWithCommas'

const useDeBridgeFeeValue = () => {
  const deBridgeContract = useDeBridgeGateContract()

  return useSingleCallResult(deBridgeContract, 'globalFixedNativeFee')
}

const de_bridge_token = 'de_bridge_token'

export const useAllDeBridgeTxs = () => {
  const transactions = useAllChainsTransactions()

  return useMemo(() => {
    return Object.values(transactions)
      .filter(tx => !!tx.bridge)
      .sort((a, b) => {
        return b.addedTime - a.addedTime
      })
  }, [transactions])
}

export const useDeBridgeAction = (
  value: BigNumber | undefined,
  toChainId: SupportedChainId | undefined,
  setPendingTx: (tx: string) => void
) => {
  const deBridgeContract = useDeBridgeGateContract()

  const actionType = `${de_bridge_token}_${value?.toString()}`

  const addPopup = useAddPopup()

  const fees = useDeBridgeFeeValue()

  const { account, chainId = SupportedChainId.ARBITRUM_ONE } = useActiveWeb3React()

  const fromAddress = useBridgableWXfiAddress()

  const { symbol } = useERC20Symbol(fromAddress)

  const dataFunc = useCallback(
    async (showPopup?: boolean) => {
      const payableFee = fees && fees.result?.[0]

      if (!account || !toChainId) {
        return undefined
      }

      // send 1 ether from Ethereum to BNB
      const message = new evm.Message({
        tokenAddress: fromAddress,
        amount: value || ZERO,
        chainIdTo: toChainId,
        receiver: account,
        autoParams: new evm.SendAutoParams({
          executionFee: '0',
          fallbackAddress: account,
          flags: new Flags(),
          data: '0x', // nothing to call on the dst chain, just bridge wrapped ether
        }),
      })

      // the resulting tuple of args to be then passed to the deBridgeGate.send() method
      const argsForSend = message.getEncodedArgs()

      try {
        const txAddress = await deBridgeContract?.populateTransaction.send(...argsForSend, {
          value: payableFee,
        })

        return txAddress
      } catch (e) {
        showPopup &&
          addPopup({
            msg: {
              success: false,
              title: <>Transaction Error</>,
              description: <>Can not populate transaction or not enough balance for fee</>,
            },
          })

        return undefined
      }
    },
    [account, addPopup, fees, deBridgeContract, value, toChainId, fromAddress]
  )

  const handleTx = useCallback(
    (tx: TransactionResponse) => {
      setPendingTx(tx.hash)
    },
    [setPendingTx]
  )

  const params = useMemo(() => {
    if (!toChainId) {
      return undefined
    }

    return { bridge: { fromChainId: chainId, toChainId } }
  }, [chainId, toChainId])

  console.log('params', params)

  return useTxTemplate(
    'Bridge',
    actionType,
    `Bridged ${formatDecimal(value || ZERO)} ${symbol} tokens`,
    dataFunc,
    handleTx,
    'Something goes wrong with bridge',
    undefined,
    params
  )
}

export const useSwapStatus = (transactionHash: string, fromChainId: SupportedChainId, toChainId: SupportedChainId) => {
  const deBridgeContract = useDeBridgeGateContract()

  const addPopup = useAddPopup()

  const { account } = useActiveWeb3React()

  const [status, setStatus] = useState<{
    isConfirmed: boolean
    isExecuted: boolean
    isSigned: boolean
    claimArgs?: evm.ClaimArgs
  }>({
    isConfirmed: false,
    isExecuted: false,
    isSigned: false,
    claimArgs: undefined,
  })

  const activeToken = useBridgableWXfiAddress()

  useEffect(() => {
    const fetch = async () => {
      if (!activeToken || !account || !fromChainId || !toChainId || !transactionHash) {
        return setStatus({
          isConfirmed: false,
          isExecuted: false,
          isSigned: false,
          claimArgs: undefined,
        })
      }

      const evmOriginContext: evm.Context = {
        provider: RPC_URLS[fromChainId][0],
      }

      // find all submissions submitted in your transaction by its hash
      // returns an array of Submission objects
      const submissions = await evm.Submission.findAll(transactionHash, evmOriginContext)

      const [submission] = submissions

      console.log('submissions', submissions)

      if (!submission) {
        return setStatus({
          isConfirmed: false,
          isExecuted: false,
          isSigned: false,
          claimArgs: undefined,
        })
      }

      // check if submission if confirmed: validator nodes wait a specific block
      // confirmations before sign the message. Currently, 12 blocks is expected
      // for most supported EVM chains (256 for Polygon).
      const isConfirmed = await submission.hasRequiredBlockConfirmations()

      console.log('isConfirmed', isConfirmed)

      // there is also a bunch of useful properties that describe the submission, e.g.
      console.log('cross-chain asset ID transferred: ', submission.debridgeId)
      console.log('amount transferred to', submission.amount, submission.receiver)

      /* ------ Destination ------ */

      const evmDestinationContext: evm.Context = {
        // provide a URL to the RPC node of the 🛬destination chain
        provider: RPC_URLS[toChainId][0],
      }

      if (isConfirmed) {
        const claim = await submission.toEVMClaim(evmDestinationContext)

        console.log('claim', claim)

        // check if claim has been signed by enough validators
        const isSigned = await claim.isSigned()

        // check if this claim has been already executed
        const isExecuted = await claim.isExecuted()

        console.log({ isSigned, isExecuted })

        return setStatus({
          isConfirmed,
          isExecuted,
          isSigned,
          claimArgs: await claim.getEncodedArgs(),
        })
      }

      setStatus({
        isConfirmed,
        isExecuted: false,
        isSigned: false,
        claimArgs: undefined,
      })
    }

    fetch()
  }, [account, addPopup, deBridgeContract, fromChainId, toChainId, transactionHash, activeToken])

  return status
}

export const useClaimDeBridgeAction = (
  transactionHash: string,
  isConfirmed: boolean,
  isSigned: boolean,
  isExecuted: boolean,
  claimArgs?: evm.ClaimArgs,
  setPendingTx?: (tx: string) => void
) => {
  const deBridgeContract = useDeBridgeGateContract()

  const actionType = `lz_claim_bridge_token_${transactionHash?.toString()}`

  const addPopup = useAddPopup()

  const { account } = useActiveWeb3React()

  const fromAddress = useBridgableWXfiAddress()

  const dataFunc = useCallback(
    async (showPopup?: boolean) => {
      if (!account) {
        return undefined
      }

      // get claim args
      if (isConfirmed && isSigned && !isExecuted && claimArgs) {
        try {
          const txAddress = await deBridgeContract?.populateTransaction.claim(...claimArgs)

          return txAddress
        } catch (e) {
          showPopup &&
            addPopup({
              msg: {
                success: false,
                title: <>Transaction Error</>,
                description: <>Can not populate transaction or not enough balance for fee</>,
              },
            })

          return undefined
        }
      }

      return undefined
    },
    [account, addPopup, deBridgeContract, isConfirmed, isSigned, isExecuted, claimArgs]
  )

  const handleTx = useCallback(
    (tx: TransactionResponse) => {
      setPendingTx && setPendingTx(tx.hash)
    },
    [setPendingTx]
  )

  return useTxTemplate(
    'Bridge',
    actionType,
    `Claimed wXFI tokens from bridge!`,
    dataFunc,
    handleTx,
    'Something goes wrong with bridge/swap',
    undefined
  )
}
