import { Stack } from '@mui/material'
import lpMpxIcon from 'assets/icons/tokens/lpMPX.svg'
import lpUsdIcon from 'assets/icons/tokens/lpUsd.svg'
import lpXfiIcon from 'assets/icons/tokens/lpXFI.svg'
import xfiIcon from 'assets/icons/tokens/xfi.svg'
import { AssetLogo } from 'components/AssetLogo/AssetLogo'
import { TokenSymbol } from 'components/blocks/AmountInput/useAppCoins'
import Column from 'components/Column'
import { Divider } from 'components/MUI'
import { RowBetween, RowGapped } from 'components/Row'
import TokenBalance from 'components/TokenBalance/TokenBalance'
import { useEMpxXfiLPAddress, useStakingXusdUsdtLpAddress, useStakingXusdWethLpAddress } from 'constants/app-contracts'
import { useSwapTokensList } from 'pages/Swap/utils'
import { useMemo } from 'react'
import styled from 'styled-components'
import { TYPE } from 'theme/theme'

const Img = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
`

export default function TokensBalance() {
  const { result: swappableAssets } = useSwapTokensList()

  const xusdWethLp = useStakingXusdWethLpAddress()
  const xUsdUsdtLp = useStakingXusdUsdtLpAddress()
  const mpxXfiLp = useEMpxXfiLPAddress()

  const fixedTokens = useMemo(() => {
    return [
      {
        symbol: TokenSymbol.lpXFI,
        icon: lpXfiIcon,
        label: TokenSymbol.lpXFI,
        currency: TokenSymbol.lpXFI,
        token_address: xusdWethLp,
      },
      {
        symbol: 'lpUsd',
        icon: lpUsdIcon,
        label: 'lpUsd',
        currency: 'lpUsd',
        token_address: xUsdUsdtLp,
      },
      {
        symbol: 'lpMPX',
        icon: lpMpxIcon,
        label: 'lpMPX',
        currency: 'lpMPX',
        token_address: mpxXfiLp,
      },
    ]
  }, [xusdWethLp, xUsdUsdtLp, mpxXfiLp])

  return (
    <Stack divider={<Divider />} gap="12px">
      <RowBetween padding="0 8px">
        <TYPE.body color="dark70">Token</TYPE.body>
        <TYPE.body color="dark70">Balance</TYPE.body>
      </RowBetween>

      <RowBetween>
        <RowGapped gap="12px" width="fit-content">
          <Img src={xfiIcon} alt="XFI" />
          <Column>
            <TYPE.body color="dark">XFI</TYPE.body>

            <TYPE.body color="dark70">XFI</TYPE.body>
          </Column>
        </RowGapped>
        <RowGapped width="fit-content" gap="4px">
          <TokenBalance coin={{ symbol: TokenSymbol.xfi }} typographyProps={{ fontSize: '16px' }} />
          XFI
        </RowGapped>
      </RowBetween>

      {fixedTokens.map(({ icon, symbol, label, currency, token_address }) => {
        return (
          <RowBetween key={symbol}>
            <RowGapped gap="12px" width="fit-content">
              <Img src={icon} alt={symbol} />
              <Column>
                <TYPE.body color="dark">{label}</TYPE.body>

                <TYPE.body color="dark70">{currency}</TYPE.body>
              </Column>
            </RowGapped>
            <RowGapped width="fit-content" gap="4px">
              <TokenBalance coin={{ symbol, address: token_address }} typographyProps={{ fontSize: '16px' }} />
              {currency}
            </RowGapped>
          </RowBetween>
        )
      })}

      {swappableAssets?.map(item => {
        const {
          address,
          data: { symbol, name },
        } = item

        if (symbol.toLowerCase() === TokenSymbol.xfi) return null

        return (
          <RowBetween key={address}>
            <RowGapped gap="12px" width="fit-content">
              <AssetLogo symbol={symbol.toLowerCase()} size={20} />
              <Column>
                <TYPE.body color="dark">{name}</TYPE.body>

                <TYPE.body color="dark70">{symbol}</TYPE.body>
              </Column>
            </RowGapped>

            <RowGapped width="fit-content" gap="4px">
              <TokenBalance
                coin={{
                  symbol,
                  address,
                }}
                typographyProps={{ fontSize: '16px' }}
              />
              {symbol}
            </RowGapped>
          </RowBetween>
        )
      })}
    </Stack>
  )
}
