import replaceSvg from 'assets/icons/replace.svg'
import { ApproveCheckerSwap, ApproveCheckerWXfi, ConfirmInWalletBlock } from 'components/Approval/ApproveTx'
import { AmountInputWithBalance } from 'components/blocks/AmountInput/AmountInput'
import { IAppToken, TokenSymbol } from 'components/blocks/AmountInput/useAppCoins'
import { ButtonMainStyle } from 'components/Button'
import { AutoColumn } from 'components/Column'
import { FormActionBtn } from 'components/FormActionBtn/FormActionBtn'
import { WarningBlock } from 'components/WarningBlock/WarningBlock'
import { BigNumber } from 'ethers'
import { Dots } from 'pages/Pool/styleds'
import { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { TYPE } from 'theme/theme'
import { ZERO } from 'utils/isZero'

import { PendingSwapView } from './PendingView'
import { useSwap, useSwapAmountIn, useSwapAmountOut, useSwapTokens } from './utils'

const ExchangeBlock = styled.div`
  position: relative;
  margin: 8px 0;
  cursor: pointer;
`

const AbsoluteSwapIcon = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  cursor: pointer;
  padding: 12px;
  border-radius: 50%;
  background: ${({ theme }) => theme.light};
`

const useAmountsSync = (
  amountInDeb: BigNumber | undefined = ZERO,
  amountOutDeb: BigNumber | undefined = ZERO,
  setAmountIn: any,
  setAmountOut: any,
  tokenIn: string,
  tokenOut: string,
  isWrappedSwap: boolean
) => {
  const [wasChangedFirst, setWasChangedFirst] = useState(false)
  const [wasChangedSecond, setWasChangedSecond] = useState(false)

  const { amount: contractAmountIn, loading: loadingAmountIn } = useSwapAmountIn(amountOutDeb, tokenIn, tokenOut)
  const { amount: contractAmountOut, loading: loadingAmountOut } = useSwapAmountOut(amountInDeb, tokenIn, tokenOut)

  useEffect(() => {
    if (!wasChangedFirst || loadingAmountIn || loadingAmountOut) {
      return
    }

    const tId = setTimeout(() => {
      if (contractAmountOut) {
        setAmountOut((prev: BigNumber | undefined) => {
          if (prev?.eq(contractAmountOut)) {
            return prev
          }

          return contractAmountOut
        })
      } else if (isWrappedSwap) {
        setAmountOut((prev: BigNumber | undefined) => {
          if (prev?.eq(amountInDeb)) {
            return prev
          }

          return amountInDeb
        })
      }

      setWasChangedFirst(false)
    })

    return () => clearTimeout(tId)
  }, [contractAmountOut, setAmountOut, amountInDeb, isWrappedSwap, wasChangedFirst, loadingAmountIn, loadingAmountOut])

  useEffect(() => {
    if (!wasChangedSecond || loadingAmountIn || loadingAmountOut) {
      return
    }

    const tId = setTimeout(() => {
      if (contractAmountIn) {
        setAmountIn((prev: BigNumber | undefined) => {
          if (prev?.eq(contractAmountIn)) {
            return prev
          }

          return contractAmountIn
        })
      } else if (isWrappedSwap) {
        setAmountIn((prev: BigNumber | undefined) => {
          if (prev?.eq(amountOutDeb)) {
            return prev
          }

          return amountOutDeb
        })
      }

      setWasChangedSecond(false)
    })

    return () => clearTimeout(tId)
  }, [contractAmountIn, setAmountIn, amountOutDeb, isWrappedSwap, wasChangedSecond, loadingAmountIn, loadingAmountOut])

  return { loadingAmountIn, loadingAmountOut, setWasChangedFirst, setWasChangedSecond }
}

export default function SwapBlock() {
  const {
    tokenIn,
    tokenOut,
    amountIn,
    amountOut,
    setPendingTx,
    noValue,
    pendingTx,
    tokenInModel,
    tokenOutModel,
    setAmountIn,
    setAmountOut,
    tokensInList,
    setTokenIn,
    tokensOutList,
    loadingAssets,
    setTokenOut,
  } = useSwapTokens()

  const {
    pending,
    action,
    txInfo,
    calledWallet,
    path,
    loadingPath,
    isWrappedSwap,
    iswXfiToXfi,
    swappedIn,
    swappedOut,
    isError,
  } = useSwap(tokenIn, tokenOut, amountIn, amountOut, setPendingTx)

  const { loadingAmountIn, loadingAmountOut, setWasChangedFirst, setWasChangedSecond } = useAmountsSync(
    amountIn,
    amountOut,
    setAmountIn,
    setAmountOut,
    tokenIn,
    tokenOut,
    isWrappedSwap
  )

  const onSwapTokens = useCallback(() => {
    setTokenIn(tokenOut)
    setTokenOut(tokenIn)
    setWasChangedFirst(true)
  }, [tokenIn, tokenOut, setTokenIn, setTokenOut, setWasChangedFirst])

  const handleTokenChangeFirst = useCallback(
    (addressOrSymbol: string) => {
      setTokenIn(addressOrSymbol)
      setWasChangedFirst(true)
    },
    [setTokenIn, setWasChangedFirst]
  )

  const handleTokenChangeSecond = useCallback(
    (addressOrSymbol: string) => {
      setTokenOut(addressOrSymbol)
      setWasChangedSecond(true)
    },
    [setTokenOut, setWasChangedSecond]
  )

  const ConfirmBlock = useMemo(() => {
    return (
      <ConfirmInWalletBlock calledWallet={calledWallet}>
        {noValue ? (
          <ButtonMainStyle disabled={noValue}>Enter an amount</ButtonMainStyle>
        ) : (
          <ButtonMainStyle onClick={action} disabled={isError || pending}>
            <FormActionBtn pending={pending} txInfo={txInfo} labelActive="Swap" labelInProgress="Swapping" />
          </ButtonMainStyle>
        )}
      </ConfirmInWalletBlock>
    )
  }, [calledWallet, noValue, pending, txInfo, isError, action])

  const handleInputFirst = useCallback(
    (v?: BigNumber) => {
      setAmountIn(v)

      if (!v || (v?.isZero && v.isZero())) {
        setAmountOut(ZERO)
      }

      setWasChangedFirst(true)
    },
    [setAmountIn, setAmountOut, setWasChangedFirst]
  )

  const handleInputSecond = useCallback(
    (v?: BigNumber) => {
      setAmountOut(v)

      if (!v || (v?.isZero && v.isZero())) {
        setAmountIn(ZERO)
      }

      setWasChangedSecond(true)
    },
    [setAmountIn, setAmountOut, setWasChangedSecond]
  )

  if (pendingTx) {
    return (
      <PendingSwapView
        onBack={() => setPendingTx('')}
        amount={swappedIn}
        color="orange"
        hash={pendingTx}
        txInfo={txInfo}
        assetIn={tokenInModel as IAppToken}
        assetOut={tokenOutModel as IAppToken}
        amountIn={swappedIn}
        amountOut={swappedOut}
      />
    )
  }

  const pathError = !isWrappedSwap ? path?.Error : undefined

  return (
    <>
      <AutoColumn>
        <AmountInputWithBalance
          useBalanceAsMax
          inputValue={amountIn}
          setInputValue={handleInputFirst}
          disabled={loadingAssets || loadingAmountIn}
          rightTokenOptions={tokensInList as any[]}
          rightToken={tokenInModel as any}
          onChangeRightToken={handleTokenChangeFirst}
        />

        <ExchangeBlock onClick={onSwapTokens}>
          <AbsoluteSwapIcon src={replaceSvg} />
        </ExchangeBlock>

        <AmountInputWithBalance
          hideMax
          useBalanceAsMax
          inputValue={amountOut}
          setInputValue={handleInputSecond}
          disabled={loadingAssets || loadingAmountOut}
          rightToken={tokenOutModel as any}
          rightTokenOptions={tokensOutList as any[]}
          onChangeRightToken={handleTokenChangeSecond}
          validateBalanceExceedsZero={false}
        />
      </AutoColumn>

      {pathError && amountIn && !amountIn.isZero() && <WarningBlock text={pathError} />}

      {loadingAmountIn || loadingAmountOut || loadingPath ? (
        <ButtonMainStyle disabled>
          <Dots>
            <TYPE.black fontSize={14} fontWeight={500} color="textSubtle">
              Loading best path
            </TYPE.black>
          </Dots>
        </ButtonMainStyle>
      ) : noValue || pathError ? (
        <ButtonMainStyle disabled={noValue}>Enter an amount</ButtonMainStyle>
      ) : tokenIn && tokenIn === TokenSymbol.xfi ? (
        ConfirmBlock
      ) : isWrappedSwap ? (
        iswXfiToXfi ? (
          <ApproveCheckerWXfi border={amountIn || ZERO} token={tokenIn}>
            {ConfirmBlock}
          </ApproveCheckerWXfi>
        ) : (
          ConfirmBlock
        )
      ) : (
        <ApproveCheckerSwap border={amountIn || ZERO} token={tokenIn}>
          {ConfirmBlock}
        </ApproveCheckerSwap>
      )}
    </>
  )
}
