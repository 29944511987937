export const LOCAL_STORAGE_FIELDS = {
  APP_PASSWORD: 'APP_PASSWORD',
  BRIDGE_TAB: 'BRIDGE_TAB',
  VESTING_TAB: 'VESTING_TAB',
  GET_TAB: 'GET_TAB',
  PROFILE_TAB: 'PROFILE_TAB',
  REWARDS_TAB: 'REWARDS_TAB',
  MANAGE_LOAN: 'MANAGE_LOAN',
  XUSD_TAB: 'XUSD_TAB',
} as const
