import { ApproveCheckerSwap, ConfirmInWalletBlock } from 'components/Approval/ApproveTx'
import { AppToggler } from 'components/AppToggler/AppToggler'
import { AmountInputWithBalance } from 'components/blocks/AmountInput/AmountInput'
import { IAppToken, TokenSymbol } from 'components/blocks/AmountInput/useAppCoins'
import { ButtonGreenStyle } from 'components/Button'
import { AutoColumn } from 'components/Column'
import { FormActionBtn } from 'components/FormActionBtn/FormActionBtn'
import { GetOverview } from 'components/GetOverview/GetOverview'
import { WarningBlock } from 'components/WarningBlock/WarningBlock'
import { useWXFIAddress } from 'constants/app-contracts'
import { ZERO_ADDRESS } from 'constants/misc'
import { BigNumber } from 'ethers'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { useCallback, useEffect, useState } from 'react'
import { ZERO } from 'utils/isZero'

import { PendingAddLiquidityView, PendingRemoveLiquidityView } from './PendingView'
import { useAddLiquiditySwap, useLiquidityTokens, useRemoveLiquidity, useRemoveLiquidityTokens } from './utils'

const TAB_IDS = {
  ADD: 'add',
  REMOVE: 'remove',
}

const TABS = [
  {
    id: TAB_IDS.ADD,
    title: 'Add',
  },
  {
    id: TAB_IDS.REMOVE,
    title: 'Remove',
  },
]

export default function LiqudityBlock({
  disabled,
  inputToken0,
  inputToken1,
  lp,
}: {
  lp: string
  disabled?: boolean
  inputToken0?: string
  inputToken1?: string
}) {
  const [tab, setTab] = useLocalStorage<string>(`Tab_LIQUIDITY-${inputToken0}-${inputToken1}`, TABS[0].id)

  return (
    <>
      <AppToggler tab={tab} setTab={setTab} tabs={TABS} />

      {tab === TAB_IDS.ADD && <AddLiqudityBlock disabled inputToken0={inputToken0} inputToken1={inputToken1} lp={lp} />}

      {tab === TAB_IDS.REMOVE && inputToken0 && inputToken1 && (
        <RemoveLiqudityBlock lp={lp} inputToken0={inputToken0} inputToken1={inputToken1} />
      )}
    </>
  )
}

function AddLiqudityBlock({
  disabled,
  inputToken0,
  inputToken1,
  lp,
}: {
  disabled?: boolean
  inputToken0?: string
  inputToken1?: string
  lp: string
}) {
  const {
    tokenIn,
    tokenOut,
    amountIn,
    amountOut,
    setPendingTx,
    noValue,
    pendingTx,
    tokenInModel,
    tokenOutModel,
    setAmountIn,
    setAmountOut,
    tokensInList,
    setTokenIn,
    tokensOutList,
    loadingAssets,
    setTokenOut,

    loadingReserves,
    reserves,
    pair,
  } = useLiquidityTokens(inputToken0, inputToken1)

  const wxfiToken = useWXFIAddress()

  const [wasChangedFirst, setWasChangedFirst] = useState(false)
  const [wasChangedSecond, setWasChangedSecond] = useState(false)

  useEffect(() => {
    if (!wasChangedFirst) {
      return
    }

    const tId = setTimeout(() => {
      if (reserves && tokenIn && tokenOut) {
        const reservesFirst = reserves[(tokenIn === TokenSymbol.xfi ? wxfiToken : tokenIn).toLowerCase()]
        const reservesSecond = reserves[(tokenOut === TokenSymbol.xfi ? wxfiToken : tokenOut).toLowerCase()]

        if (amountIn && reservesSecond && reservesFirst) {
          const amountOutNew = amountIn.mul(reservesSecond).div(reservesFirst)

          setAmountOut(prev => {
            if (prev?.eq(amountOutNew)) {
              return prev
            }

            return amountOutNew
          })
        }
      }

      setWasChangedSecond(false)
    })

    return () => clearTimeout(tId)
  }, [amountIn, reserves, tokenIn, tokenOut, setAmountIn, setAmountOut, wxfiToken, wasChangedFirst])

  useEffect(() => {
    if (!wasChangedSecond) {
      return
    }

    const tId = setTimeout(() => {
      if (amountOut && reserves && tokenIn && tokenOut) {
        const reservesFirst = reserves[(tokenIn === TokenSymbol.xfi ? wxfiToken : tokenIn).toLowerCase()]
        const reservesSecond = reserves[(tokenOut === TokenSymbol.xfi ? wxfiToken : tokenOut).toLowerCase()]

        if (amountOut && reservesSecond && reservesFirst) {
          const amountInNew = amountOut.mul(reservesFirst).div(reservesSecond)

          setAmountIn(prev => {
            if (prev?.eq(amountInNew)) {
              return prev
            }

            return amountInNew
          })
        }
      }

      setWasChangedFirst(false)
    })

    return () => clearTimeout(tId)
  }, [amountOut, reserves, tokenIn, tokenOut, setAmountIn, setAmountOut, wxfiToken, wasChangedSecond])

  const { pending, action, isError, txInfo, calledWallet, path, loadingPath } = useAddLiquiditySwap(
    tokenIn,
    tokenOut,
    amountIn,
    amountOut,
    setPendingTx
  )

  const setInputFirst = useCallback(
    (v?: BigNumber) => {
      v && setAmountIn(v)
      setWasChangedFirst(true)
    },
    [setAmountIn]
  )

  const setInputSecond = useCallback(
    (v?: BigNumber) => {
      v && setAmountOut(v)
      setWasChangedSecond(true)
    },
    [setAmountOut]
  )

  if (pendingTx) {
    return (
      <PendingAddLiquidityView
        onBack={() => setPendingTx('')}
        amount={amountIn}
        color="orange"
        hash={pendingTx}
        token={tokenInModel.symbol as TokenSymbol}
        txInfo={txInfo}
        assetIn={tokenInModel as IAppToken}
        assetOut={tokenOutModel as IAppToken}
        amountIn={amountIn}
        amountOut={amountOut}
      />
    )
  }

  return (
    <>
      <AutoColumn gap="16px">
        <AmountInputWithBalance
          useBalanceAsMax
          inputValue={amountIn}
          setInputValue={setInputFirst}
          disabled={loadingAssets || loadingReserves}
          rightTokenOptions={disabled ? [] : (tokensInList as any[])}
          rightToken={tokenInModel as any}
          onChangeRightToken={setTokenIn}
        />

        <AmountInputWithBalance
          useBalanceAsMax
          inputValue={amountOut}
          setInputValue={setInputSecond}
          disabled={loadingAssets || loadingReserves}
          rightToken={tokenOutModel as any}
          rightTokenOptions={disabled ? [] : (tokensOutList as any[])}
          onChangeRightToken={setTokenOut}
          validateBalanceExceedsZero={false}
        />
      </AutoColumn>

      {pair && pair === ZERO_ADDRESS && (
        <WarningBlock text="This pair does not exist, you will need to add liquidity to create it" />
      )}

      {path?.Error && amountIn && !amountIn.isZero() ? <WarningBlock text={path?.Error} /> : null}

      <ApproveCheckerSwap border={amountIn || ZERO} token={tokenIn}>
        <ApproveCheckerSwap border={amountOut || ZERO} token={tokenOut}>
          <ConfirmInWalletBlock calledWallet={calledWallet}>
            {noValue ? (
              <ButtonGreenStyle disabled={noValue}>Enter an amount</ButtonGreenStyle>
            ) : (
              <ButtonGreenStyle onClick={action} disabled={isError || loadingPath}>
                <FormActionBtn
                  pending={pending || loadingPath}
                  txInfo={txInfo}
                  labelActive="Add Liqudity"
                  labelInProgress="Adding"
                />
              </ButtonGreenStyle>
            )}
          </ConfirmInWalletBlock>
        </ApproveCheckerSwap>
      </ApproveCheckerSwap>

      {pair && pair !== ZERO_ADDRESS && <GetOverview lp={lp} pair={pair} />}
    </>
  )
}

function RemoveLiqudityBlock({
  lp,
  inputToken0,
  inputToken1,
}: {
  lp: string
  inputToken0: string
  inputToken1: string
}) {
  const {
    tokenIn,
    tokenOut,
    amountIn,
    setPendingTx,
    noValue,
    pendingTx,
    tokenInModel,
    setAmountIn,

    pair,
  } = useRemoveLiquidityTokens(inputToken0, inputToken1, lp)

  const { pending, action, isError, txInfo, calledWallet, path, loadingPath } = useRemoveLiquidity(
    tokenIn,
    tokenOut,
    amountIn,
    setPendingTx
  )

  const setInputFirst = useCallback(
    (v?: BigNumber) => {
      v && setAmountIn(v)
    },
    [setAmountIn]
  )

  if (pendingTx) {
    return (
      <PendingRemoveLiquidityView
        onBack={() => setPendingTx('')}
        amount={amountIn}
        color="orange"
        hash={pendingTx}
        token={tokenInModel.symbol as TokenSymbol}
        txInfo={txInfo}
      />
    )
  }

  return (
    <>
      <AutoColumn gap="16px">
        <AmountInputWithBalance
          useBalanceAsMax
          inputValue={amountIn}
          setInputValue={setInputFirst}
          rightToken={tokenInModel as any}
        />
      </AutoColumn>

      {pair && pair === ZERO_ADDRESS && (
        <WarningBlock text="This pair does not exist, you will need to add liquidity to create it" />
      )}

      {path?.Error && amountIn && !amountIn.isZero() ? <WarningBlock text={path?.Error} /> : null}

      <ApproveCheckerSwap border={amountIn || ZERO} token={pair}>
        <ConfirmInWalletBlock calledWallet={calledWallet}>
          {noValue ? (
            <ButtonGreenStyle disabled={noValue}>Enter an amount</ButtonGreenStyle>
          ) : (
            <ButtonGreenStyle onClick={action} disabled={isError || loadingPath}>
              <FormActionBtn
                pending={pending || loadingPath}
                txInfo={txInfo}
                labelActive="Remove Liqudity"
                labelInProgress="Removing"
              />
            </ButtonGreenStyle>
          )}
        </ConfirmInWalletBlock>
      </ApproveCheckerSwap>

      {pair && pair !== ZERO_ADDRESS && <GetOverview lp={lp} pair={pair} />}
    </>
  )
}
